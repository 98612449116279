.blog {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    min-height: 80vh;
    padding: 2rem;
    overflow-x: hidden;
}


.blog--header {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 1rem;
}


.blog--header h1 {
    margin-bottom: 40px;
    font-size: 3.5rem;
    font-family: var(--primaryFont);
}

.blog--body {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.blog--bodyContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 4.5rem;
}

.blog--viewAll {
    width: 100%;
    margin-top: 1rem;
    padding: 2rem;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.blog--viewAll a button {
    outline: none;
    border: none;
    width: 150px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-transform: inherit;
    border-radius: 45px;
    font-size: 1.05rem;
    font-family: var(--primaryFont);
    font-weight: 500;
    padding-left: 1.5rem;
    cursor: pointer;
}


@media (min-width: 992px) and (max-width: 1380px) {

}


@media screen and (max-width: 992px) {
    .blog--bodyContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
    }
}

@media screen and (max-width: 800px) {
    .blog--header h1 {
        font-size: 3rem;
        margin-bottom: 30px;
    }
}

@media screen and (max-width: 600px) {
    .blog--header h1 {
        font-size: 2.5rem;
        margin-bottom: 20px;
    }
    .blog--viewAll {
        position: relative;
        margin-top: 2.5rem;
    }
    .blog--viewAll a {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);  
    }
}

@media screen and (max-width: 400px) {

}
  
@media only screen and (min-device-width: 320px) and (max-device-width: 
  480px) and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 2/3) {

}


@media only screen and (device-width: 768px) {

}